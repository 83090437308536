@font-face {
  font-family: "collingar";   /*Can be any text*/
  src: local("Collingardemo"),
    url("./components/font/fonts/Collingardemo-eZ3Op.woff") format("woff");
  font-display: fallback;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans');




.g-recaptcha {
  transform:scale(0.77);
  -webkit-transform:scale(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}
body {
  overflow-x: hidden;
  width:100vw;
  /* overflow-x: hidden; */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 font-family: 'collingar';
}
h1,h2,h3,h4,h5,h6{
  font-family: 'collingar';
}
.fonttext{
 font-family: 'collingar';
}
.containe{
  margin-bottom: 5vh;
}
.foo{
  padding-block: 3rem;
  /* margin-top:50px; */
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
/* video::-webkit-media-controls {
  display:none !important;
} */

#touchwithus{
  height:50vh;
  width:100vw;
  text-align: center;
}
#h2our{
  transform: translate(0,24vh);
}
#h6button{
  transform: translate(0,25vh);
}
#abcd{
  /* -webkit-appearance:none; */
  background: none;
  border: hidden;
}
.newsectionss{
  /* height: fit-content; */
  /* height: max-content; */
  height: 70vh;
}
#country_inputform{
  padding-top: 0;
  display: flex;
  border: none;
}
#country_inputform1{
  padding-top: 0;
  display: flex;
  border: none;
}

html{
  overflow-x: hidden;
}

code {
 font-family: 'collingar';
}@media screen and (min-width: 1480px) {
  .navbarsection{
    display:contents;
  }
  .navbar-brand{
    margin-left: 8vh;
  }
  #abcd{
    margin-right: 8vh;
    size: 200px;
    /* -webkit-appearance:none; */
  }
  #logoimage{
    width:200px;
    /* margin-left: 10vh; */
  }
  #section_brands{
    padding-inline: 10vh;
  }
  #title_vitext{
    padding-left: 10vh;
  }
  .videoTaghome{
    object-position: 50% 0%;
    min-width: 100vw;
    height: 90vh;
    object-Fit:cover;
  }
  
}
@media screen and (min-width: 915px) {
  .navbarsection{
    display:contents;
    
  }
  .navbar-brand{
    margin-left: 8vh;
  }
  #abcd{
    margin-right: 8vh;
    size: 200px;
  }
  #logoimage{
    width:150px;
    /* margin-left: 10vh; */
  }
  
  .videoTaghome{
    padding-inline: 10vh;
    object-position: 50% 0%;
    min-width: 100vw;
    height: 90vh;
    object-Fit:cover;
  }
  #section_brands{
    padding-inline: 10vh;
  }
  #section_ourstore{
    /* height:100vh; */
    width:100vw;
  }
  #img_ourstore{
    /* padding:5vw; */
    /* padding-bottom: 0; */
    /* padding-inline: 5vw; */
    object-fit: cover;
    /* height:80vh; */
    width:100vw;
    
  }
  .videoTag{
    object-position: 50% 0%;
    max-width: 100vw;
    height: 100vh;
    object-Fit:cover;
  }
  .h1ph{
    font-size: 5rem;
    text-align: center;
  }
  #firstvideosectionph{
    display: none;
  }
  
  /* #title_vitext{
      display:none;
  } */
  /* #crosserv{
    display: none;
  } */
  #threesixtyph{
    display: none;
  }
  #topimages{
    display: none;
  }
  #prevph{
    display: none;
  }
  #nextph{
    display: none;
    width: fit-content;
    
  }
  #threesixtylg{
    margin: auto;
  }
}
@media screen and (min-width: 601px) and (max-width: 915px) {
  
  .videoTaghome{
    padding-inline:0;
    object-position: 50% 0%;
    min-width: 100vw;
    height: 100vh;
    object-Fit:cover;
  }
  .videosequence__image {
    position: relative;
    flex: 0 0 0%;
    object-fit: fill;
  }
  .videoTag{
    object-position: 50% 0%;
    max-width: 100vw;
    height: 100vh;
    object-Fit:cover;
  }
  #threesixtyph{
    margin: auto;
  }

  .h1ph{
    font-size: 4rem;
    text-align: center;
  }

#firstvideosection{
  display: none;
}

#title_vitext{
    display:none;
}
#crosserv{
  display: none;
}
#productnameimage{
  width:100vw;
  float: none!important;
  padding-left:20vh!important;
}
#threesixtylg{
  display:none;
}
#prevlg{
  display:none;
}
#nextlg{
  display:none;
}
#autoplay{
  display: none;
}
#stop{
  display: none;
}
}
@media screen and (min-width: 470px) and (max-width: 915px) {
  .foo{
    padding-block: 1.5rem;
    /* margin-top:50px; */
  }

  /* .styled_content_section{
    height:30vh;
  } */
  #title_h2 {
    font-size: 3rem;
   font-family: 'collingar';
    line-height: 1.05;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #acacac;

  }

  #title_h3 {
    font-size: 2rem;
    line-height: 1.05;
   font-family: 'collingar';
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #000000;

  }
  .navbarsection{
    display:contents;
  }
  .navbar-brand{
    margin-left: 4vh;
  }
  #abcd{
    margin-right: 4vh;
  }
  #logoimage{
    width:125px;
    /* margin-left: 10vh; */
  }
  #section_ourstore{
    /* height:100vh; */
    width:100vw;
  }
  #img_ourstore{
    /* padding:5vw; */
    object-fit: cover;
    /* height:100vh; */
    width:100vw;
    
  }
  .h1ph{
    font-size: 4rem;
    text-align: center;
  }
}
@media screen and (min-width: 850px) {

  /* .styled_content_section{
    display: none;
  } */
  #just_for_space{
    height: 0;
  }
  .hr_for_conten_brake{
    display: none;
  }
}
.circle_on_img{
  border-radius: 50%;
  height: 450px;
  width: 450px;
}
.circle_filled_on_img{
  border-radius: 50%;
  height: 400px;
  width: 400px;
  opacity: .7;
}
@media screen and (min-width: 850px)and (max-width: 1100px) {
  #our_store_h3{
    font-size: 39px;
  }
  #our_store_h2{
    font-size: 24px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 370px;
    width: 370px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 350px;
    width: 350px;
    opacity: .7;
  }
}
@media screen and (min-width: 750px)and (max-width: 850px) {
  #our_store_h3{
    font-size: 35px;
  }
  #our_store_h2{
    font-size: 22px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 370px;
    width: 370px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 350px;
    width: 350px;
    opacity: .7;
  }
  /* .styled_content_section{
    display: none;
  } */
  #just_for_space{
    height: 5%;
  }
  .hr_for_conten_brake{
    display: none;
  }
}
@media screen and (min-width: 650px)and (max-width: 750px) {
  #our_store_h3{
    font-size: 30px;
  }
  #our_store_h2{
    font-size: 20px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 370px;
    width: 370px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 350px;
    width: 350px;
    opacity: .7;
  }
  /* .styled_content_section{
    display: none;
  } */
  #just_for_space{
    height: 8%;
  }
  .hr_for_conten_brake{
    display: none;
  }
}
@media screen and (min-width: 550px)and (max-width: 650px) {
  #our_store_h3{
    font-size: 25px;
  }
  #our_store_h2{
    font-size: 15px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 370px;
    width: 370px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 350px;
    width: 350px;
    opacity: .7;
  }
  .circle_text_h1{
    font-size: 1.6rem;
  }
  .circle_text_h4{
    font-size: 1.1rem;
  }
  /* .styled_content_section{
    display: none;
  } */
  #just_for_space{
    height: 15%;
  }
  .hr_for_conten_brake{
    display: none;
  }
  /* .imgsections{
    position: inherit;
    transform: rotate(90deg);
  
} */
}
@media screen and (min-width: 470px) and (max-width: 550px) {
  #our_store_h3{
    font-size: 25px;
  }
  #our_store_h2{
    font-size: 15px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 350px;
    width: 350px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 320px;
    width: 320px;
    opacity: .7;
  }
  .circle_text_h1{
    font-size: 1.5rem;
  }
  .circle_text_h4{
    font-size: 1rem;
  }
  #just_for_space{
    height: 5%;
  }
  /* .styled_content_section{
    display: flex;
    position: absolute;
    bottom: 0;
  } */
  /* .imgsections{
    transform: rotate(90deg);
  
} */
}
@media screen and (min-width: 360px) and (max-width: 470px) {
  #our_store_h3{
    font-size: 20px;
  }
  #our_store_h2{
    font-size: 10px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 340px;
    width: 340px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 310px;
    width: 310px;
    opacity: .7;
  }
  .circle_text_h1{
    font-size: 1.5rem;
  }
  .circle_text_h4{
    font-size: 1rem;
  }
  /* .imgsections{
      transform: rotate(90deg);
    
  } */
  .foo{
    padding-block: 1.3rem;
    /* margin-top:50px; */
  }
  
  #just_for_space{
    height: 6%;
  }
  /* .styled_content_section{
    display: flex;
    height:50vh;
    position: absolute;
    bottom: 0;
  } */
  #title_h2 {
    font-size: 2rem;
   font-family: 'collingar';
    line-height: 1.05;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #acacac;
    

  }

  /* link: https://instadesign.in:2083/
  username: unilarfreightser
  password: unilar@2233 */

  #title_h3 {
    font-size: 1.5rem;
    line-height: 1.05;
    font-family: 'collingar';
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #080f0f;

  }
  .navbarsection{
    display:contents;
  }
  .navbar-brand{
    margin-left: 3vh;
  }
  #abcd{
    margin-right: 3vh;
    size: 200px;
  }
  #logoimage{
    width:100px;
    /* margin-left: 10vh; */
  }
  #section_ourstore{
    /* height:100vh; */
    width:100vw;
  }
  #img_ourstore{
    /* padding:5vw; */
    object-fit: cover;
    /* height:100vh; */
    width:100vw;
    
  }
  .h1ph{
    font-size: 3rem;
    text-align: center;
  }
}
@media screen and (max-width: 360px) {
  #our_store_h3{
    font-size: 20px;
  }
  #our_store_h2{
    font-size: 10px;
  }
  .circle_on_img{
    border-radius: 50%;
    height: 330px;
    width: 330px;
  }
  .circle_filled_on_img{
    border-radius: 50%;
    height: 300px;
    width: 300px;
    opacity: .7;
  }
  .circle_text_h1{
    font-size: 1.5rem;
  }
  .circle_text_h4{
    font-size: 1rem;
  }
  .foo{
    padding-block: 1rem;
    /* margin-top:50px; */
  }
  #just_for_space{
    height: 10%;
  }
  /* .styled_content_section{
    display: flex;
    height:50vh;
    position: absolute;
    bottom: 0;
  } */
  #title_h2 {
    font-size: 2rem;
   font-family: 'collingar';
    line-height: 1.05;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #acacac;

  }

  #title_h3 {
    font-size: 1.5rem;
    line-height: 1.05;
   font-family: 'collingar';
    font-weight: 500;
    letter-spacing: -0.015em;
    color: #080f0f;


  }
  .navbarsection{
    display:contents;
  }
  .navbar-brand{
    margin-left: 1vh;
  }
  #abcd{
    margin-right: 1vh;
    size: 200px;
  }
  #logoimage{
    width:100px;
    /* margin-left: 10vh; */
  }
  
  #section_ourstore{
    /* height:100vh; */
    width:100vw;
  }
  #img_ourstore{
    /* padding:5vw; */
    object-fit: cover;
    /* height:100vh; */
    width:100vw;
    
  }
  .h1ph{
    font-size: 2rem;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  #threesixtyph{
    margin: auto;
    overflow-x: hidden;
  }
  
  #firstvideosection{
    display: none;
  }
  #threesixtylg{
    display:none;
  }
  #prevlg{
    display:none;
  }
  #nextlg{
    display:none;
  }
  #autoplay{
    display:none;
  }
  #stop{
    display:none;
  }
  #crosserv{
    display: none;
  }

  #contact_text{
    display:none;
  }
  #contactlogo{
    display: block;
    margin-top:15%;
    margin-left: auto;
    margin-right: auto;
    width:35%;
  }
  .crossRevealImage img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
  #title_vitext{
    display:none;
  }
  #h4{
   font-family: 'collingar';
    display: none;
  }

  .landscape__wrapper {
    display:none;
  }
}
@media screen and (min-width: 601px) {
  /* #contactsection{
    margin-top: 20vh;
  } */

  .form-control{
    width:80vw;
    margin-bottom: 3%;
  }
  #submitButton{
    margin-top:5%;
  }
  #contact_textlg{
    display:none;
  }
  #contactlogo{
    display: block;
    margin-top:10%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    width:30%;
  }
  #contact_text{
    padding-top:5vh;
  }
  .crossRevealImage img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    /* padding:10vh */
  }
  #title_carousal {
    display: none;
  }
}


#html{
  overflow: hidden;
 font-family: 'collingar';
}
#body{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: linear-gradient(to right, black 25%, #1d1515, black 75%);
  display: grid;

  justify-items: center;
  perspective: 600px;
  perspective-origin: center calc((var(--s, 1) * 1px) + 50%);
}
#body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

#vid2{
  scroll-behavior: smooth;
}

.videocontn{
  margin: 10vh;
  /* margin-bottom:0px; */
}
.box {
  background: linear-gradient(to right, white 70%, #b9b0b0);
  width: 100vw;
  height: 300px;
  transform-style: preserve-3d;
  transform: translateX(-50%) rotateY(65deg);
  transform-origin: right center;
}

.box2 {
  background: linear-gradient(to left, white 70%, #b9b0b0);
  transform: translateX(50%) rotateY(-65deg);
  transform-origin: left center;
}

@media screen and (max-width: 600px){
  #lap{
    display: none;
  }
  .videosequence__image {
    position: relative;
    flex: 0 0 0%;
    object-fit: fill;
  }
  .canvas {
    position: absolute;
    height:100vh;
    transform: translate(-50%, -50%);
  }
  .videoTag{
    object-position: 50% 0%;
    max-width: 100vw;
    height: 100vh;
    object-Fit:cover;
  }
  .pdimage{
    height:100vh;
    object-Fit:fill;
  }
}

@media screen and (min-width: 601px){
  #phone{
    display:none;
  }
  #discover{
    padding-top: 5vh;
  }
  #prodc{
    height:100vh;
    width:100vw;
    /* overflow-y:scroll; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0px;
    margin-bottom: 20vh;
    margin-top: 10vh;
  }
  #prodc::-webkit-scrollbar{
    display: none;
  }

  .videosequence__container{
    overflow:hidden;
  }
  .videosequence__image {
    /* overflow: hidden; */
    margin-top: 0;
    position: relative;
    flex: 0 0 0%;
    object-fit: cover;
    
  }
  .pdimage{
    height:100vh;
    width:100vw;
    object-Fit:fill;
  }
  /* .videoTag{
    width: 90vw;
  } */
  #vidf{
    height: 100vh;
    width: 100vh;
  }
  .canvas {
    height:100vh;
    position: absolute;
    /* overflow-y: hidden; */
    /* width:100vw; */
    transform: translate(-50%, -50%);
    /* min-width: 100vw; */
    /* min-height: 100vh; */
    /* max-height: 100vh; */
  }
  .canvas1 {
    height:95vh;
    position: absolute;
    background-color:white;
    /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important; */

    /* overflow-y: hidden; */
    /* width:vw; */
    transform: translate(-50%, -50%);
    /* min-width: 100vw;
    min-height: 100vh; */
    max-height: 80vh;
    
  }
}



.wrapper {
	font-family: 'Open Sans', sans;
	text-align: center;
  color: #424040;
}

#threesixty {
	margin: 0 auto;
  user-select: none;
}

.g-hub {
  color: #444;
  font-size: 0.9em;
}

.buttons-wrapper {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .button{
  box-decoration-break: none;
} */

/* .button {
  position: relative;
  appearance: none;
  border: none;
  padding: 40px 7px 5px;
  cursor: pointer;
  background: #ffffff;
  font-size: 0;

  &::before ,
  &::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    width: 15px;
    height: 15px;
    transform: translate(-45%) rotate(-45deg);
  }
  
  &::after {
    transform: translate(5%) rotate(-45deg);
  }
}

#next {
  &::before {
    transform: translate(-90%) rotate(135deg);
  }
  
  &::after {
    transform: translate(-50%) rotate(135deg);
  }
} */
@media screen and (max-width: 600px) {
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 40%;
    left: 0;
    /* margin-left: -5%; */
  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 20px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 10px;
  }
  .marquis_div{
    margin-left:9%;
    margin-right:9%;
  }
  .crossptag{
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 12px;

  }
  
}
@media screen and (min-width: 600px) and (max-width: 915px) {
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 100%;
    top: 40%;
    left: 0;
    /* margin-left: -5%; */
  }
  #contact-container{
    width:100vw;
    /* padding-block: 5vh; */
    padding-inline:1vh;
  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 15px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 9px;
  }

  .marquis_div{
    margin-left:9%;
    margin-right:9%;
  }
  .crossptag{
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 12px;

  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    margin-top:3rem;
  }
}
@media screen and (min-width: 915px)and (max-width: 1200px) {
  #our_store_h3{
    font-size: 40px;
  }
  #our_store_h2{
    font-size: 25px;
  }
  .maker_details{
    width:22%;
  }
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 107%;
    top: 40%;
    margin-left: -5%;
  }
  #contact-container{
    width:100vw;
    /* padding-block: vh; */
    padding-inline: 10vh;
  }
  #section_carousel{
    position: relative;
    padding-inline: 10vh;
    padding-bottom: 5vh;
  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 15px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 9px;
  }
  .marquis_div{
    margin-left:8%;
    margin-right:8%;
  }
  .crossptag{
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 13px;

  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    margin-top:3rem;
  }
}
@media screen and (min-width: 1200px)and (max-width: 1300px) {
  #our_store_h3{
    font-size: 50px;
  }
  #our_store_h2{
    font-size: 30px;
  }
  .maker_details{
    width:18%;
  }
}
@media screen and (min-width: 1300px)and (max-width: 1450px) {
  #our_store_h3{
    font-size: 60px;
  }
  #our_store_h2{
    font-size: 35px;
  }
  .maker_details{
    width:15%;
  }
}


@media screen and (min-width: 1200px)and (max-width: 1450px) {

  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 107%;
    top: 40%;
    margin-left: -5%;
  }
  #contact-container{
    width:100vw;
    /* padding-block: 5vh; */
    padding-inline: 20vh;
  }
  #section_carousel{
    position: relative;
    padding-inline: 10vh;
    padding-bottom: 6vh;
  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 15px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 10px;
  }
  .marquis_div{
    margin-left:10%;
    margin-right:10%;
  }
  .crossptag{
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 14px;

  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    margin-top:3rem;
  }
}
@media screen and (min-width: 1450px)and (max-width: 1550px) {
  #our_store_h3{
    font-size: 70px;
  }
  #our_store_h2{
    font-size: 40px;
  }
  .maker_details{
    width:15%
  }
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 107%;
    top: 40%;
    margin-left: -5%;
  }
  #contact-container{
    width:100vw;
    padding-block: 15vh;
    padding-inline: 30vh;
  }
  #section_carousel{
    position: relative;
    padding-inline: 20vh;
    padding-bottom: 8vh;
  }
  .crossptag{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 16px;

  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 16px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 10px;
  }
  .marquis_div{
    margin-left:10%;
    margin-right:10%;
  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    margin-top:3rem;
  }
}
@media screen and (min-width: 1550px)and (max-width: 1820px){
  .maker_details{
    width:13%
  }
}
@media screen and (min-width: 1820px)and (max-width: 2070px){
  #our_store_h3{
    font-size: 70px;
  }
  #our_store_h2{
    font-size: 40px;
  }
  .maker_details{
    width:12%
  }
}
@media screen and (min-width: 2070px){
  #our_store_h3{
    font-size: 70px;
  }
  #our_store_h2{
    font-size: 40px;
  }
  .maker_details{
    width:10%
  }
}



@media screen and (min-width: 1550px)and (max-width: 1920px){
  
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 107%;
    top: 40%;
    margin-left: -5%;
  }
  #contact-container{
    width:100vw;
    padding-block: 15vh;
    padding-inline: 30vh;
  }
  #section_carousel{
    position: relative;
    padding-inline: 30vh;
    padding-bottom: 10vh;
  }
  .crossptag{
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 1.3rem;

  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 19px;
  }
  .slideranimation_boxtext_white{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 12px;
  }
  .marquis_div{
    margin-left:10%;
    margin-right:10%;
  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    /* margin-top:3rem; */
  }
}
@media screen and (min-width: 1920px){
  
  .custom_button_carousel{
    position: absolute;
    text-align: center;
    width: 107%;
    top: 40%;
    margin-left: -5%;
  }
  #contact-container{
    width:100vw;
    padding-block: 15vh;
    padding-inline: 30vh;
  }
  #section_carousel{
    position: relative;
    padding-inline: 40vh;
    padding-bottom: 10vh;
  }
  .crossptag{
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 1.6rem;

  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 30px;
  }
  .slideranimation_boxtext{
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-size: 24px;
  }
  .marquis_div{
    margin-left:11.5%;
    margin-right:11.5%;
  }
  .crossimgs{
    filter: invert(1);
    object-fit:contain;
    width:35%;
    height:fit-content;
    top:auto;
    /* margin-top:3rem; */
  }
}


/* CSS rules for Safari */
/* @media screen and (-webkit-min-device-pixel-ratio:0) { */
  /* CSS rules specific to Safari */
  /* .player-wrapper{
    display: none;
  }
} */

/* CSS rules for other browsers */
/* @media screen and (min-resolution: 2dppx) { */
  /* CSS rules for browsers other than Safari */
  /* #videoElement{ */
    /* display: none; */
  /* } */
/* } */

#videoElementsafari{
    display: none;
  }
@supports (-webkit-touch-callout: none) {
  /* CSS rules specific to Safari */
  #videoElementother{
    display: none;
  }
  #videoElementsafari{
    display: block;
  }
}