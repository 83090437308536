
/*--------------------------------------------------------------
 collapsible-sidebar-section
--------------------------------------------------------------*/

.sidenav {
    width: 40%;
    height: 100%;
    position: fixed;
    z-index: 2103;
    top: 0;
    right: -100%;
    overflow-x: hidden;
    transition: 1.3s;
    padding: 80px 10px 10px 60px;
    background-color: #000;
}
.sidenav a {
    padding:2%;
    display: block;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #F1F1F1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 45px;
    margin-left: 50px;
}

#overlay {
    z-index: 2;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
}

@media (max-width:450px){
    .footer_sections{
        font-size: 10px;
    }
    .sidenav{
        width:100%;
        right: -100%;
        padding: 80px 10px 10px 10px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

@media (max-width:820px) and (min-width:451px){
    .footer_sections{
        font-size: 11px;
    }
    .sidenav a {
        font-size: 18px;
    }
    .sidenav{
        width:60%;
        right: -60%;
        padding: 80px 10px 10px 30px;
    }
}

@media screen and(max-height: 450px) {
    .footer_sections{
        font-size:10px;
    }

    .sidenav {
        padding-top: 15px;
        padding: 80px 10px 10px 50px;
    }
    .sidenav a {
        font-size: 18px;
    }

}

